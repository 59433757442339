import { NextPage } from 'next';
import { ArticleStatus } from '@wix/answers-api';
import { Error } from '../components/Error';
import { LOCALES, PRODUCTION_DOMAIN } from '../constants';
import { HttpStatusCode } from '../types';

type ErrorPageProps = {
  statusCode: number;
  englishArticleUri?: string;
};

const ErrorPage: NextPage<ErrorPageProps> = ({
  statusCode,
  englishArticleUri,
}) => <Error statusCode={statusCode} englishArticleUri={englishArticleUri} />;

ErrorPage.getInitialProps = async ({ res, query, locale, err }) => {
  const statusCode =
    res?.statusCode || err?.statusCode || HttpStatusCode.NOT_FOUND;

  const { articleTitle } = query;

  let englishArticleUri = '';
  if (
    statusCode === HttpStatusCode.NOT_FOUND &&
    articleTitle &&
    locale !== LOCALES.EN
  ) {
    try {
      const response = await fetch(
        `https://${PRODUCTION_DOMAIN}/api/v1/articles/uri/${articleTitle}?locale=${LOCALES.EN}`
      );
      const { uri, status } = await response.json();
      englishArticleUri = status === ArticleStatus.PUBLISHED ? uri : '';
    } catch (error) {
      englishArticleUri = '';
    }
  }
  return { statusCode, englishArticleUri };
};

export default ErrorPage;
